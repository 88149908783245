<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div
            class="md-layout-item"
            :class="userIsAgentsManager ? 'md-size-25' : 'md-size-33'"
          >
            <ProgrammeStatusesSelect
              v-model="filters.status"
              multiple
            />
          </div>
          <div
            v-if="userIsAgentsManager"
            class="md-layout-item md-size-25"
          >
            <AgentsSelect
              v-model="filters.agent_id"
              :agent-groups="agentGroupsManagerIds"
            />
          </div>
          <div
            class="md-layout-item"
            :class="userIsAgentsManager ? 'md-size-25' : 'md-size-33'"
          >
            <CoursesSelect
              v-model="filters.course_id"
            />
          </div>
          <div
            class="md-layout-item"
            :class="userIsAgentsManager ? 'md-size-25' : 'md-size-33'"
          >
            <CountriesAutocomplete
              v-model="filters.country_id"
              label="Nationality"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.start_date_from"
              label="Start Date From"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.start_date_to"
              label="Start Date To"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.tuition_finish_date_from"
              label="Tuition Finish Date From"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.tuition_finish_date_to"
              label="Tuition Finish Date To"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getProgrammes"
              @onShowDetails="onShowDetails"
              @onApprove="onApprove"
              @onDeny="onDeny"
              @onSendSignRequest="onSendSignRequest"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormDate } from '@/components/Inputs';
import {
  ProgrammeStatusesSelect, CoursesSelect, AgentsSelect, CountriesAutocomplete,
} from '@/components/Inputs/selects';

export default {
  components: {
    VueTable,
    FormDate,
    AgentsSelect,
    CoursesSelect,
    ProgrammeStatusesSelect,
    CountriesAutocomplete,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'programme_id',
            mask: 'Programme ID',
            no_format: true,
            sortable: true,
          },
          {
            title: 'student.student_number',
            mask: 'student number',
            sortable: true,
          },
          {
            title: 'student.first_name',
            mask: 'given name',
            sortable: true,
          },
          {
            title: 'student.last_name',
            mask: 'surname',
            sortable: true,
          },
          {
            title: 'agent.name',
            sort_value: 'agent.first_name',
            mask: 'agent',
            sortable: true,
          },
          {
            title: 'start_date',
            mask: 'start date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'finish_date',
            mask: 'finish date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'tuition_finish_date',
            mask: 'tuition finish date',
            sortable: true,
            dateFormat: true,
            strong: true,
          },
          {
            title: 'status',
            sortable: true,
          },
          {
            title: 'class_schedule.period',
            mask: 'Period',
            sortable: true,
          },
          {
            title: 'is_signed',
            mask: 'Accepted T & C',
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
        ],
        actions: [
          {
            buttonClass: 'md-primary',
            tooltip: 'Re-Send SignRequest',
            callback: 'onSendSignRequest',
            icon: 'mail',
          },
          {
            buttonClass: 'md-success',
            tooltip: 'Approve Programme',
            callback: 'onApprove',
            icon: 'thumb_up_alt',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Deny Programme',
            callback: 'onDeny',
            icon: 'thumb_down_alt',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
        ],
        values: {},
      },
      filters: {
        country_id: null,
        course_id: null,
        status: null,
        start_date_from: null,
        start_date_to: null,
        tuition_finish_date_from: null,
        tuition_finish_date_to: null,
      },
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getProgrammes(params) {
      const parameters = params;

      this.request(`${this.$API.PROGRAMMES}`.paginableUrl(parameters), 'get', { ...this.filters }, ({ data }) => {
        const aux = data;

        // Deactivate Approve/Deny Buttons for non Waiting for Approval programmes
        aux.data = aux.data.map((x) => {
          const a = x;
          if (x.status !== 'Waiting for Approval') {
            a.onApprove = x.status === 'Fully Paid';
            a.onDeny = false;
          }
          a.onSendSignRequest = !x.is_signed && this.signRequestActive;

          return a;
        });
        this.vTable.values = aux;
      });
    },
    onShowDetails(item, isMiddleClick) {
      this.openLink({ name: 'ProgrammeDetails', params: { id: item.programme_id } }, isMiddleClick);
    },
    onApprove(programme) {
      this.fireConfirm(
        'Approving Programme',
        'Are you sure you want to Approve this Programme?',
      ).then(() => {
        this.request(`${this.$API.PROGRAMMES}${programme.programme_id}/approve`, 'put', {
          approved: true,
        }, () => {
          this.fireSuccess('Programme approved succesfully!');
          // Reset Table after response
          this.$refs.vtable.init();
        });
      });
    },
    onDeny(programme) {
      this.fireConfirm(
        'Denying Programme',
        'Are you sure you want to Deny this Programme?',
      ).then(() => {
        this.request(`${this.$API.PROGRAMMES}${programme.programme_id}/approve`, 'put', {
          approved: false,
        }, () => {
          this.fireSuccess('Programme denied succesfully!');
          // Reset Table after response
          this.$refs.vtable.init();
        });
      });
    },
    onSendSignRequest(programme) {
      this.fireConfirm(
        'Re-Sending SignRequest',
        'Are you sure you want to re-send a SignRequest for this programme?',
      ).then(() => {
        this.request(`${this.$API.PROGRAMMES}${programme.programme_id}/resend_sign_request`,
          'post',
          {},
          () => {
            this.fireSuccess('Email sent succesfully!');
          });
      });
    },
    onFilter() {
      this.$refs.vtable.init();
    },
  },
};
</script>

<style scoped>
</style>
